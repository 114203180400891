.pdf-reader-container {
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pdf-page {
    max-width: 100%;
    height: auto;
  }
  
  .pdf-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    width: 100%;
  }
  
  .pdf-controls button {
    margin: 0 10px;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .pdf-controls button:disabled {
    background-color: #ccc;
  }
  
  .pdf-controls button:hover {
    background-color: #0056b3;
  }
  