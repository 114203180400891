/* Navbar styles */
nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

nav a {
  text-decoration: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

nav a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Active link styling */
nav .active {
  background-color: rgba(255, 255, 255, 0.3);
}

/* AppBar styles */
.AppBar {
  background-color: #007bff;
}

/* Toolbar styles */
.Toolbar {
  display: flex;
  justify-content: space-between;
}
